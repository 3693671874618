import React from 'react';
import Navbar from './navbar';
import Footer from './footer';
import NextLink from 'next/link';
import { logAmplitudeEvent } from '../lib/amplitude';

class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props)
   
      // Define a state variable to track whether is an error or not
      this.state = {
        hasError: false,
        errorMessage: '', // Initialize with an empty error message
      };
    }
    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI
   
      return { hasError: true, errorMessage: error.toString() }
    }
    componentDidCatch(error, errorInfo) {
      // You can use your own error logging service here
      logAmplitudeEvent("Error Occurred", { error: error.toString(), errorInfo: errorInfo.componentStack, stackTrace: error.stack, pageRoute: window.location.pathname });
      
    }
    render() {
      // Check if the error is thrown
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return (
          <div className="min-h-screen max-w-full overflow-x-hidden box-border flex flex-col items-center">
            <Navbar />
            <h2 className="text-3xl font-bold mt-5">Oops, there was an error!</h2>
            <p className="mt-3">{this.state.errorMessage}</p>
            <button
              type="button"
              className="btn mt-5"
              onClick={() => this.setState({ hasError: false })}
            >
              Try again?
            </button>
            <p className="px-5 mt-5 mb-2 text-center">If the problem persists, please <a href="mailto:support@stylesense.io" class="underline text-blue-500">contact support</a> or post in <NextLink className="text-blue-500 underline" href="https://www.reddit.com/r/stylesense/">r/stylesense</NextLink>.</p>
            <Footer />
          </div>
        )
      }
   
      // Return children components in case of no error
   
      return this.props.children
    }
  }
   
  export default ErrorBoundary;