import '../styles/globals.css'
import { createPagesBrowserClient } from '@supabase/auth-helpers-nextjs';
import { SessionContextProvider } from '@supabase/auth-helpers-react';
import { useState, createContext, useEffect } from 'react';
import useAmplitudeInit from '../../lib/amplitude';
import { CheckoutProvider } from '../../lib/CheckoutContext';
import Script from "next/script";
import ErrorBoundary from '../../components/errorboundary';
import { StatsigProvider } from "statsig-react";
import { TrackUserAuthentication } from '../../components/trackuser';
// import { SpeedInsights } from '@vercel/speed-insights/next';


export const UserContext = createContext();

export default function App({ Component, pageProps }) {
  // Create a new supabase browser client on every first render.
  // const [environmentTier, setEnvironmentTier] = useState('development');
  const [supabaseClient] = useState(() => createPagesBrowserClient())
  const [userData, setUserData] = useState({"display_name": "", "feature_release_emails": 0, "updated": false, "deleted": false,"signedOut": false});
  useAmplitudeInit();

  const updateUserData = (newValue) => {
    setUserData(newValue);
  };

  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const getSession = async () => {
      const { data, error } = await supabaseClient.auth.getSession();
  
      if (error) {
        console.error('Error getting session:', error);
        return;
      }
      
  
      if (data && data.session && data.session.user) {
        setUserId(data.session.user.id);
      }
    };
  
    getSession();
  }, [supabaseClient, userData]);

  // useEffect(() => {
  //   // This will only run on the client-side, after SSR
  //   if (typeof window !== 'undefined') {
  //     const tier = window.location.hostname.includes('stylesense.io')
  //       ? 'production'
  //       : 'development';
  //     setEnvironmentTier(tier);
  //   }
  // }, []);

  return (
    <>
    <Script id="Adsense-id" 
      async
      onError={(e) => { console.error("Script failed to load", e);}}
      src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7239948404927194"
      strategy='lazyOnload'
      crossorigin="anonymous"
    />
    <StatsigProvider
      sdkKey={process.env.NEXT_PUBLIC_STATSIG_CLIENT_KEY}
      waitForInitialization={true}
      user={{ userID: userId }}
      options={{
        environment: {
          tier: process.env.NEXT_PUBLIC_STATSIG_ENV
        }
      }}      
    >    
      <UserContext.Provider value={{ userData, updateUserData }}>
        <SessionContextProvider
          supabaseClient={supabaseClient}
          initialSession={pageProps.initialSession}
        >
          <CheckoutProvider>
            <ErrorBoundary>
              {userId && (
              <TrackUserAuthentication userId={userId} />
              )}              
              <Component {...pageProps} />
              {/* <SpeedInsights /> */}
            </ErrorBoundary>
          </CheckoutProvider>
        </SessionContextProvider>  
      </UserContext.Provider>
    </StatsigProvider>
    </>
  );
}
