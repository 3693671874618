import { useEffect, useContext } from 'react';
import { logAmplitudeEvent } from '../lib/amplitude';
import { Statsig } from 'statsig-react';
import { useUser } from '@supabase/auth-helpers-react';
import { UserContext } from '../src/pages/_app';

export function TrackUserAuthentication({ userId }) {
  const user = useUser();
  const { userData, updateUserData } = useContext(UserContext);
  useEffect(() => {  
    if (user && userData['updated']) {
      // console.log("track", user, user.app_metadata, userData);
      const createdDate = new Date(userData.created_at).toDateString();
      const currentDate = new Date().toDateString();
      console.log(createdDate, currentDate)      
      const isNewUser = createdDate >= currentDate;
      
      if (isNewUser) {
        logAmplitudeEvent('User Registered', {
          provider: user.app_metadata.provider
        });
        Statsig.logEvent('User Registered', null, {provider: user.app_metadata.provider, registration_date: createdDate});
      } else {
        logAmplitudeEvent('User Signed In', {
          provider: user.app_metadata.provider
        });
        Statsig.logEvent('User Signed In', null, {provider: user.app_metadata.provider, registration_date: createdDate});
      }
      
    //   router.push('/profile');
    }
  }, [userId, userData]);

  return null;
}
