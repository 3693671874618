import NextLink from 'next/link';

export default function Footer() {
    return (
    <footer className={`footer footer-center text-base-content p-4`} contentEditable={false}>
      <div className="grid-flow-col w-full">
        {/* <div></div> */}
        <p className="place-self-center justify-self-center">Copyright © 2024 | <NextLink href="/privacy">Privacy Policy</NextLink> | <NextLink href="/pricing">Pricing</NextLink>   </p>
        {/* <NextLink href="" className="place-self-end justify-self-end"><BiDonateHeart size={"2rem"}/></NextLink> */}
        {/* <div className="tooltip tooltip-left place-self-end justify-self-end" data-tip="Support me on Kofi">
        <NextLink href="https://ko-fi.com/vastava" className="place-self-end justify-self-end"><BiDonateHeart size={"2rem"}/></NextLink>
        </div> */}
      </div>
    </footer>
    )
}