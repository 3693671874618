// CheckoutContext.js
import React, { createContext, useState } from 'react';

const CheckoutContext = createContext();

const CheckoutProvider = ({ children }) => {
  const [checkoutOpen, setCheckoutOpen] = useState(false);
  const [uiLocation, setUiLocation] = useState("dummy"); // Add triggerSource state
  const [quantity, setQuantity] = useState(5);
  const [paymentMode, setPaymentMode] = useState("payment");
  // const [selectedPlan, setSelectedPlan] = useState("payment");
  // const [clientSecret, setClientSecret] = useState(null);
  const [subscribeClientSecret, setSubscribeClientSecret] = useState(null);
  const [paymentClientSecret, setPaymentClientSecret] = useState(null);
  const [switchCheckout, setSwitchCheckout] = useState(false);
  const [weeklyClientSecret, setWeeklyClientSecret] = useState(null);

  return (
    <CheckoutContext.Provider value={{ checkoutOpen, setCheckoutOpen, uiLocation, setUiLocation, quantity, setQuantity, paymentMode, setPaymentMode, subscribeClientSecret, setSubscribeClientSecret, paymentClientSecret, setPaymentClientSecret, weeklyClientSecret, setWeeklyClientSecret, switchCheckout, setSwitchCheckout }}>
      {children}
    </CheckoutContext.Provider>
  );
};

export { CheckoutProvider, CheckoutContext };